.control-chart {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.control-chart-samples {
	display: flex;
	flex-direction: row;
	min-height: 200px;
	flex: 1;
}

.control-chart-sample {
	flex: 1;
	padding: 0 var(--u-4);
	min-width: var(--u-24);
	display: grid;
	grid-template-rows: 1fr 30px;
}

.control-chart-sample .scl-tooltip-wrapper {
	width: 100%;
	height: 100%;
}

.control-chart-sample-bar-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: stretch;
}

.control-chart-sample-bar {
	background: var(--col-primary-500);
}

.control-chart-sample-label {
	font-size: var(--fs-9);
	color: var(--col-grey-500);
	text-align: center;
}