.table {
    /*border: 1px solid var(--col-grey-200);*/
    /*border-collapse: collapse;*/
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    position: relative;
}

.table-fw {
    width: 100%;
}

.tbl-sticky-top th {
    position: sticky;
    top: 0;
}

.table thead tr > th {
    color: var(--col-primary-50);
    font-size: var(--fs-9);
    letter-spacing: -0.2px;
    background: var(--col-primary-500);
    border: 0;
    border-top: 1px solid var(--col-primary-600);
    border-left: 1px solid var(--col-primary-600);
    overflow: hidden;
    white-space: nowrap;
}

.table thead th:last-of-type {
    border-right: 1px solid var(--col-primary-600);
}

.table thead tr:last-of-type > th {
    border-bottom: 1px solid var(--col-primary-600);
}

.table td {
    border: 0;
    border-top: 1px solid var(--col-grey-100);
    border-left: 1px solid var(--col-grey-100);
}

.table td:last-of-type {
    border-right: 1px solid var(--col-grey-100);
}

.table tr:last-of-type td {
    border-bottom: 1px solid var(--col-grey-100);
}

.table td,
.table th {
    text-align: left;
    padding: 4px;
    /*border: 1px solid var(--col-grey-200);*/
}

.table tr:nth-of-type(odd) > td {
    background: var(--col-primary-50);
}

.table td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.table tr:not(.no-tr-hover):hover + tr > td {
    padding-top: 4px;
    border-top: none;
}

.table tr:not(.no-tr-hover):hover > td {
    border-color: var(--col-primary-200);
    border-bottom: 1px solid var(--col-primary-200);
    /*margin-top: -1px;*/ /* Not sure why we added this, but removing it seems to fix things? */
    background: var(--col-primary-100);
}

.tbl-row-expanded.tbl-row-expanded.tbl-row-expanded.tbl-row-expanded.tbl-row-expanded.tbl-row-expanded > td {
    border-top-color: var(--col-primary-300);
    border-bottom-color: var(--col-primary-300);
}

.tbl-row-expanded.tbl-row-expanded.tbl-row-expanded.tbl-row-expanded.tbl-row-expanded.tbl-row-expanded > td:first-of-type {
    border-left-color: var(--col-primary-300);
}

.tbl-row-expanded.tbl-row-expanded.tbl-row-expanded.tbl-row-expanded.tbl-row-expanded.tbl-row-expanded > td:last-of-type {
    border-right-color: var(--col-primary-300);
}

.tbl-row-expansion.tbl-row-expansion.tbl-row-expansion.tbl-row-expansion.tbl-row-expansion.tbl-row-expansion > td {
    background: var(--col-white);
    border-top-color: var(--col-primary-300);
}

.table td .scl-button, .table td .scl-destructive-button {
    min-height: 24px;
    height: 24px;
    line-height: 24px;
}

.table .tbl-center {
    text-align: center;
}

.table .tbl-fit {
    width: 0.1%;
    white-space: nowrap;
}

.table .tbl-align-right {
    text-align: end;
    font-variant-numeric: tabular-nums;
}

.table .tbl-align-center {
    text-align: center;
}

.table .tbl-faded {
    color: var(--col-grey-300);
}

.table .tbl-inverted.tbl-inverted.tbl-inverted {
    background: var(--col-primary-500);
    color: white;
}

/* PAGINATION */
.pagination {
    list-style: none;
}

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: var(--u-16);
    padding-bottom: var(--u-16);
    padding-right: var(--u-24);
}

.pagination a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: var(--u-48);
    height: var(--u-48);
    /*background: var(--col-grey-50);*/
    border-radius: 2px;
    margin-left: 2px;
    user-select: none;
    font-weight: bold;
}

.pagination a:hover {
    color: var(--col-primary-500);
    background: var(--col-grey-100);
}

.pagination .active a {
    background: var(--col-primary-500);
    color: white;
}

.fake-subtree-indicator {
    position: absolute;
    top: 0;
    left: 14px;
    border-left: 2px solid var(--col-primary-300);
    border-bottom: 2px solid var(--col-primary-300);
    width: 32px;
    height: 28px;
    border-bottom-left-radius: 4px;
}

.tbl-checkbox {
    padding-top: var(--u-4);
    margin: -8px;
}

.tbl-checkbox .scl-checkbox {
    margin: 0;
    width: 20px;
    height: 20px;
}

.diff-old {
    font-size: 0.8em;
    line-height: 0.9em;
    opacity: 0.5;
}