.scl-form-element {
    width: 100%;
    outline-color: var(--col-primary-500);
}

.form-element {
    border: 1px solid var(--col-grey-200);
    height: var(--u-24);
    width: 100%;
}

.form-element:focus,
.form-element:active {
    border-color: var(--col-primary-500);
    outline: none;
}

.label {
    text-transform: uppercase;
    color: var(--col-primary-500);
    font-weight: bold;
    font-size: var(--fs-9);
}

label {
    display: block;
    padding-bottom: var(--u-16);
}

.btn {
    display: inline-block;
    background: var(--col-primary-500);
    text-transform: uppercase;
    color: white;
    border: 0;
    border-radius: var(--u-4);
    height: var(--u-48);
    line-height: var(--u-48);
    min-width: var(--u-48);
    text-align: center;
    font-weight: bold;
    font-size: var(--fs-10);
    padding: 0 var(--u-16);
    transition: background 0.14s ease;
    margin: 0;
    user-select: none;
    white-space: nowrap;
}

.btn.btn-flat {
    background: none;
    color: var(--col-primary-500);
}

.btn.btn-flat:hover, .btn.btn-flat:active, .btn.btn-flat:focus {
    outline: none;
    color: var(--col-primary-600);
}

.btn.btn-flat:disabled {
    color: var(--col-grey-600);
    cursor: not-allowed;
}

.planboard-controls-bar .btn {
    min-width: var(--u-36);
    line-height: var(--u-36);
    padding-top: var(--u-2);
    height: var(--u-36);
}

.planboard-controls-bar .form-element {
    margin-bottom: 0;
}

.btn span {
    margin-left: var(--u-8);
}

.btn span:nth-of-type(1) {
    margin-left: 0;
}

.btn:hover, .btn:active, .btn:focus  {
    outline: none;
    background: var(--col-primary-600);
    color: white;
}

.btn:active {
    box-shadow: 0 0 4px inset rgba(0, 0, 0, 0.2);
}

.btn:disabled {
    background: var(--col-grey-500);
    cursor: not-allowed;
}

.btn-danger:hover, .btn-danger:active, .btn-danger:focus {
    background: var(--col-red);
}

.btn.btn-secondary {
    background: var(--col-grey-200);
    color: var(--col-grey-700);
}

.btn.btn-secondary:hover, .btn.btn-secondary:active, .btn.btn-secondary:focus {
    outline: none;
    background: var(--col-grey-300);
    color: var(--col-grey-800);
}

.btn.btn-secondary:disabled {
    color: var(--col-grey-500);
    cursor: not-allowed;
}

.btn.btn-flat {
    background: none;
    color: var(--col-primary-500);
}

.btn.btn-flat:hover, .btn.btn-flat:active, .btn.btn-flat:focus {
    outline: none;
    color: var(--col-primary-600);
}

.btn.btn-flat:disabled {
    color: var(--col-grey-600);
    cursor: not-allowed;
}

.btn-group {
    display: flex;
    flex-direction: row;
}

.btn-group .scl-button {
    border-radius: 0;
    border-right: 1px solid var(--col-primary-700);
}

.btn-group .scl-button.scl-button-secondary {
    border-right: 1px solid var(--col-grey-300);
}

.btn-group .scl-button:disabled {
    border-right: 1px solid var(--col-grey-600);
}

.btn-group .scl-button:first-child {
    border-top-left-radius: var(--u-4);
    border-bottom-left-radius: var(--u-4);
}

.btn-group .scl-button:last-child {
    border-right: 0;
    border-top-right-radius: var(--u-4);
    border-bottom-right-radius: var(--u-4);
}

.btn-group-vertical {
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;
}

.btn-group-vertical .btn {
    border-bottom: 1px solid var(--col-primary-700);
    border-radius: 0;
    text-align: left;
}

.btn-group-vertical .btn-secondary {
    border-bottom: 1px solid var(--col-grey-300);
}

.btn-group-vertical .btn:disabled {
    border-bottom: 1px solid var(--col-grey-600);
}

.btn-group-vertical .btn:first-of-type {
    border-top-left-radius: var(--u-4);
    border-top-right-radius: var(--u-4);
}

.btn-group-vertical .btn:last-of-type {
    border-bottom: 0;
    border-bottom-left-radius: var(--u-4);
    border-bottom-right-radius: var(--u-4);
}

.btn-compress-icon .fa {
    transform: rotate(45deg) scale(1.5);
}

.table-buttons .btn {
    /*margin: -12px 2px;*/
}

.button-area {
    display: flex;
    flex-direction: row-reverse;
    padding-top: var(--u-16);
    gap: var(--u-16);
}

.button-area-inverted {
    justify-content: flex-start;
}

.button-area-inverted > * {
    margin-left: 0;
    margin-right: var(--u-16);
}

/* Date time stuff below */
.date-picker {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 140px;
    height: 30px;
}

.date-picker > .btn {
    position: absolute;
    right: 0;
    padding: 0;
    height: 100%;
    line-height: 24px;
    vertical-align: center;
}

.date-picker-dropdown {
    position: absolute;
    font-size: 0.8em;
    padding: var(--u-4);
    padding-bottom: var(--u-8);
    top: calc(var(--u-48) + var(--u-8));
    width: 240px;
    background: #fff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.4);
    z-index: 220;
    border-radius: 2px;
}

.date-picker-dropdown::after {
    content: '';
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    position: absolute;
    top: -5px;
    left: 10px;
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15);
}

.date-picker-dropdown::before {
    width: 0;
    height: 0;
    content: '';
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    position: absolute;
    top: -7px;
    left: 8px;
}

.date-picker-dropdown .date-picker-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}

.date-picker-dropdown .date-picker-header span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: var(--col-primary-800);
    text-transform: lowercase;
}

.date-picker-dropdown .btn {
    box-shadow: none;
}

.date-picker-dropdown table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px 1px;
}

.date-picker-dropdown table td {
    text-align: center;
    padding: 2px 0;
    border-radius: 4px;
}

.date-picker-dropdown table {
    table-layout: fixed;
}

.date-picker-dropdown table thead td {
    font-weight: bold;
}

.date-picker-dropdown table tbody tr td:nth-of-type(6),
.date-picker-dropdown table tbody tr td:nth-of-type(7) {
    background: #efefef;
}

.date-picker-dropdown .greyed {
    color: #bbb;
}

.date-picker-dropdown table tbody tr td:hover {
    background: #ddd;
}

.date-picker-dropdown table tbody tr td.selected {
    background: var(--col-grey-800);
    color: white;
}

.date-picker-dropdown table tbody tr td.selected:hover {
    background: var(--col-primary-500);
}

.time-picker {
    position: relative;
    display: flex;
    flex-direction: row;
}

.time-picker > .btn {
    font-size: 1.2em;
    position: absolute;
    right: 0;
}

.time-picker-dropdown {
    position: absolute;
    padding: 8px;
    top: 38px;
    width: 180px;
    background: #fff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.4);
    z-index: 200;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 2em;
    font-weight: bold;
}

.time-picker-dropdown::after {
    content: '';
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    position: absolute;
    top: -5px;
    left: 10px;
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15);
}

.time-picker-dropdown::before {
    width: 0;
    height: 0;
    content: '';
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 14px solid #fff;
    position: absolute;
    top: -7px;
    left: 1px;
}

.time-picker-dropdown .btn {
    box-shadow: none;
    transition: background 0.2s ease;
}

.time-picker-dropdown .btn:hover {
    background: #e5e5e5;
}

.time-picker-dropdown .time-picker-value {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.time-picker-dropdown .time-picker-colon {
    width: 6px;
    margin: 0 -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
}

.date-time-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.date-time-picker .date-picker {
    width: 62%;
}

.date-time-picker .time-picker {
    width: 37%;
}

.date-picker-dropdown tr td {
    height: 30px;
}

.toggle-label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 24px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

.toggle-label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(24px - 3px - 3px);
    height: calc(24px - 3px - 3px);
    background: #fff;
    border-radius: 90px;
    transition: 200ms;
    /*transform: translateX(-100%);*/
}

.toggle-label-checked {
    background: var(--col-primary-500);
}

.toggle-label-checked:after {
    transform: translateX(calc(34px - 100%));
}

.toggle-label:active:after {
    width: 24px;
}

.field-clear-btn:hover {
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.field-clear-btn:active {
    color: var(--col-primary-400);
    transform: scale(0.95);
    text-shadow: none;
}

.scl-form-element {
    font-family: var(--font-stack-default);
}

.react-select .react-select__control {
    background: var(--col-white);
    min-height: var(--u-32);
    height: var(--u-32);
    padding: 0;
    line-height: var(--u-32);
    border: 1px solid var(--col-grey-200);
    border-radius: var(--u-4);
    display: block;
    width: 100%;
    color: var(--col-grey-800);
    font-size: var(--fs-12);
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 4px inset rgba(0, 0, 0, 0.1);
    transition: none;
}

.react-select .react-select__control--is-focused.react-select__control--is-focused {
    padding: -1px;
    border: 2px solid var(--col-primary-500);
}

.react-select .react-select__control--is-focused.react-select__control--is-focused > * {
    margin: -1px;
}

.react-select .react-select__control .react-select__input-container {
    grid-template-columns: 0 1fr;
}

.react-select .react-select__value-container,
.react-select .react-select__value-container > * {
    min-height: var(--u-32);
    height: var(--u-32);
    padding: 0;
    margin: 0;
    line-height: calc(var(--u-32) - 2px);
}

.react-select__menu.react-select__menu {
    position: absolute;
    top: calc(var(--u-32) - 4px); /* Necessary to fix behavior in grids, idk why */
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25), 0 2px 16px rgba(0, 0, 0, 0.15);
    overflow: visible;
}

.react-select .react-select__value-container {
    padding: 0 var(--u-4);
}

.react-select .react-select__value-container.react-select__value-container,
.react-select .react-select__single-value {
    font-size: var(--fs-11);
    color: var(--col-grey-900);
}

.react-select .react-select__option--is-focused {
    background: var(--col-primary-200);
    border-radius: var(--u-2);
}

.react-select .react-select__option--is-selected {
    background: var(--col-primary-500);
    color: var(--col-grey-50);
}

.react-select.react-select--is-disabled .react-select__control {
    background: var(--col-grey-50);
    box-shadow: none;
    border-color: var(--col-grey-100);
}

.react-select.react-select--is-disabled .react-select__control:after {
    content: '';
    display: none;
}

.react-select__control {
    position: relative;
}

.react-select__control:after {
    content: "\f107";
    color: var(--col-grey-600);
    font-family: 'FontAwesome';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: var(--u-48);
    transition: transform 0.2s ease;
    transform: rotateX(0deg);
}

.react-select__input-container {
    display: inline-block;
}

/*.react-select__input {*/
/*    min-width: 100% !important;*/
/*    width: 100% !important;*/
/*    max-width: 100% !important;*/
/*    z-index: 1;*/
/*}*/

.react-select__control--menu-is-open:after {
    transform: rotateX(180deg);
}

.react-select__multi-value.react-select__multi-value.react-select__multi-value {
    border-radius: 4px;
    margin: 0 4px;
    height: calc(var(--u-32) - 8px);
    min-height: 0;
    overflow: hidden;
}

.react-select__multi-value__label.react-select__multi-value__label {
    padding: 0 4px 0 8px;
    line-height: 26px;
}