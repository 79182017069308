@import './tables.css';
@import './components.css';
@import './forms.css';
@import './pagespecific.css';
@import './control-charts.css';
@import './mobileTables.css';

:root {
    /* Color palettes used */
    --col-grey-900: #111111;
    --col-grey-800: #262626;
    --col-grey-700: #404040;
    --col-grey-600: #555555;
    --col-grey-500: #6f6f6f;
    --col-grey-400: #909090;
    --col-grey-300: #aaaaaa;
    --col-grey-200: #cfcfcf;
    --col-grey-100: #e0e0e0;
    --col-grey-50: #f8f8f8;
    --col-white: #fff;

    /*--col-primary-900: #130306;*/
    /*--col-primary-800: #3A0912;*/
    /*--col-primary-700: #751224;*/
    /*--col-primary-600: #9C1830;*/
    /*--col-primary-500: #C41E3C;*/
    /*--col-primary-400: #CF4B63;*/
    /*--col-primary-300: #DB788A;*/
    /*--col-primary-200: #E7A5B1;*/
    /*--col-primary-100: #F3D2D8;*/
    /*--col-primary-50: #F9E8EB;*/

    --col-primary-900: #101f3f;
    --col-primary-800: #1b3260;
    --col-primary-700: #254a87;
    --col-primary-600: #2d62a9;
    --col-primary-500: #357dc0;
    --col-primary-400: #479ed3;
    --col-primary-300: #7cc2e7;
    --col-primary-200: #afe0f5;
    --col-primary-100: #daf1fa;
    --col-primary-50: #f3f8f9;

    /* Blue == Primary in this case, but blue should be used for things that should be semantically blue like info boxes,
        whereas primary should be used for branding / accent colors */
    --col-blue-900: #101f3f;
    --col-blue-800: #1b3260;
    --col-blue-700: #254a87;
    --col-blue-600: #2d62a9;
    --col-blue-500: #357dc0;
    --col-blue-400: #479ed3;
    --col-blue-300: #7cc2e7;
    --col-blue-200: #afe0f5;
    --col-blue-100: #daf1fa;
    --col-blue-50: #f3f8f9;

    --col-green-900: #064E3B;
    --col-green-800: #065F46;
    --col-green-700: #047857;
    --col-green-600: #059669;
    --col-green-500: #10B981;
    --col-green-400: #34D399;
    --col-green-300: #6EE7B7;
    --col-green-200: #A7F3D0;
    --col-green-100: #D1FAE5;
    --col-green-50: #ECFDF5;

    --col-red-900: #7F1D1D;
    --col-red-800: #991B1B;
    --col-red-700: #B91C1C;
    --col-red-600: #DC2626;
    --col-red-500: #EF4444;
    --col-red-400: #F87171;
    --col-red-300: #FCA5A5;
    --col-red-200: #FECACA;
    --col-red-100: #FEE2E2;
    --col-red-50: #FEF2F2;


    --col-orange-900: hsl(25deg, 100%, 10%);
    --col-orange-800: hsl(30deg, 90%, 20%);
    --col-orange-700: hsl(33deg, 80%, 30%);
    --col-orange-600: hsl(34deg, 75%, 40%);
    --col-orange-500: hsl(34deg, 75%, 50%);
    --col-orange-400: hsl(34deg, 80%, 58%);
    --col-orange-300: hsl(37deg, 85%, 65%);
    --col-orange-200: hsl(39deg, 90%, 70%);
    --col-orange-100: hsl(40deg, 95%, 87%);
    --col-orange-50: hsl(45deg, 100%, 95%);

    --col-red: var(--col-red-500);

    /*--col-red: #bf5b5b;*/
    /*--col-green-500: #3cc41e;*/
    /*--col-orange-500: #e67220;*/
    /*--col-blue-500: #0088ff;*/

    /* Font sizes used */
    --fs-8: 8pt;
    --fs-9: 9pt;
    --fs-10: 10pt;
    --fs-11: 11pt;
    --fs-12: 12pt;
    --fs-13: 13pt;
    --fs-16: 16pt;
    --fs-18: 18pt;
    --fs-20: 18pt;
    --fs-24: 24pt;
    --fs-25: 24pt;

    /* Font stacks */
    --font-stack-default: Lato, sans-serif;

    /* Units */
    --u-1: 1px;
    --u-2: 2px;
    --u-4: 4px;
    --u-6: 6px;
    --u-8: 8px;
    --u-12: 12px;
    --u-16: 16px;
    --u-24: 24px;
    --u-32: 32px;
    --u-36: 36px;
    --u-48: 48px;
    --u-64: 64px;
    --u-96: 96px;
    --u-128: 128px;
    --u-192: 192px;
    --u-256: 256px;
    --u-384: 384px;
    --u-512: 512px;
    --u-768: 768px;
    --u-960: 960px;
    --u-1200: 1200px;
    --u-1600: 1600px;
    --u-2400: 2400px;

}

* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    color: var(--col-grey-800);
    font-size: var(--fs-11);
    line-height: 1.35em;
}

.app {
    display: flex;
    flex-direction: row;
}

.app-content {
    overflow-y: auto;
    flex: 1;
    align-self: stretch;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.sidebar {
    margin-right: calc(-1 * var(--u-256));
    width: var(--u-256);
    height: 100vh;
    padding-top: var(--u-16);
    background: var(--col-grey-800);
    color: var(--col-grey-100);
    box-shadow: none;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 50;
    transform: translate(calc(-1 * var(--u-256)));
}

.sidebar-transform-layer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
}

.sidebar, .sidebar-transform-layer {
    transition: transform 200ms ease-out, box-shadow 200ms ease-out;
}

.sidebar-open {
    transform: translateX(0);
    box-shadow: 0 0 var(--u-4) rgba(0, 0, 0, 0.3), 0 0 var(--u-16) rgba(0, 0, 0, 0.1);
}

.sidebar-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-top: var(--u-16);
}

.sidebar-link {
    display: flex;
    gap: var(--u-12);
    justify-content: space-between;
    height: 40px;
    line-height: 40px;
    padding: 0 var(--u-8);
    color: var(--col-grey-200);
    text-decoration: none;
    border-left: 4px solid transparent;
    transition: border-left-color 0.15s ease-out, background 0.15s ease-out;
    margin: 0 8px;
}

.sidebar-link:hover {
    color: var(--col-primary-300);
}

.sidebar-link-left-side {
    display: flex;
    gap: var(--u-8);
}

.sidebar-link.active {
    background: var(--col-primary-700);
    color: var(--col-primary-100);
    border-radius: 4px;
}

.sidebar-link-icon {
    width: 24px;
    text-align: center;
}

.sidebar-link + .sidebar-subitems {
    /*display: none;*/
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.sidebar-link.active + .sidebar-subitems {
    opacity: 1;
    width: auto;
    height: auto;
    margin: 4px 8px;
    padding: var(--u-8);
    transform: scaleX(1) rotateX(0deg);
}

.sidebar-subitems {
    background: rgba(255, 255, 255, 0.09);
    color: var(--col-grey-100);
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    transition: transform 150ms ease-out, opacity 150ms ease-out;
    transform-origin: center 0;
    transform: scale(0.95) rotateX(45deg);
    box-shadow: 0 0 4px inset rgba(0, 0, 0, 0.1);
}

.sidebar-subitems .sidebar-link {
    margin: 0;
    font-size: var(--fs-11);
}

.sidebar-collapsed .sidebar-link-label,
.sidebar-collapsed .sidebar-link-arrow {
    display: none;
}

.sidebar-collapsed .sidebar-subitems {
    padding: var(--u-4) 0;
}

.login-info {
    /*border-top: 2px solid var(--col-grey-200);*/
    box-shadow: 0 0 var(--u-8) rgba(0, 0, 0, 0.1);
    padding: var(--u-16);
    background: var(--col-grey-700)
}

.login-info-collapsed {
    flex: 1;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: var(--u-16);
}

.login-info-company {
    font-style: italic;
}

.button-area {
    display: flex;
    flex-direction: row-reverse;
    padding-top: var(--u-16);
    gap: var(--u-16);
}

a.scl-button {
    text-decoration: none;
}

.button {
    display: block;
    height: var(--u-32);
    line-height: var(--u-32);
    min-width: var(--u-32);
    text-align: center;
    padding: 0 var(--u-8);
    margin: 0;
    background: var(--col-grey-100);
    color: var(--col-grey-700);
    font-size: inherit;
    font-weight: bold;
    border-radius: var(--u-4);
    box-shadow: 0 var(--u-1) var(--u-2) rgba(0, 0, 0, 0.05);
    transition: all 0.15s ease;
    border: 1px solid var(--col-grey-200);
    text-decoration: none;
}

button.btn-selected,
button:active {
    border: 1px solid var(--col-primary-500);
    background: var(--col-primary-100);
    color: var(--col-primary-800);
}

/*.button:hover {*/
/*    box-shadow: 0 var(--u-1) var(--u-2) rgba(0, 0, 0, 0.2), 0 var(--u-1) var(--u-16) rgba(0, 0, 0, 0.2);*/
/*    background: var(--col-grey-50);*/
/*}*/

.button-group {
    display: flex;
    flex-direction: row;
}

.button-group > .button,
.button-group > .scl-button {
    border-radius: 0;
    border-right: 0;
}

.button-group > .button:first-of-type,
.button-group > .scl-button:first-of-type {
    border-top-left-radius: var(--u-4);
    border-bottom-left-radius: var(--u-4);
}

.button-group > .button:last-of-type,
.button-group > .scl-button:last-of-type {
    border-top-right-radius: var(--u-4);
    border-bottom-right-radius: var(--u-4);
    border-right: 1px solid var(--col-grey-200);
}

.button:not(:disabled):hover {
    box-shadow: 0 var(--u-1) var(--u-2) rgba(0, 0, 0, 0.1), 0 var(--u-1) var(--u-16) rgba(0, 0, 0, 0.07);
    background: var(--col-grey-200);
    color: var(--col-grey-800);
    border-color: var(--col-grey-300);
}

.button:not(:disabled):active {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
    background: var(--col-grey-200);
    color: var(--col-grey-800);
    border-color: var(--col-grey-300);
}

.button.primary,
.button-group > .button.primary:last-of-type {
    background: var(--col-primary-500);
    color: white;
    border-color: var(--col-primary-600);
}

.button.primary:not(:disabled):hover,
.button-group > .button.primary:not(:disabled):hover:last-of-type {
    background: var(--col-primary-600);
    border-color: var(--col-primary-700);
    color: white;
}

.button:disabled {
    opacity: 0.5;
}

.grey-page-bg {
    background: var(--col-grey-50);
    flex: 1;
}

.page {
    padding: 16px 24px;
    /*overflow: auto;*/
    flex: 1;
    position: relative;
    margin: 0 auto;
    max-width: 1280px;
    height: 100%;
}

.page.page-mobile {
    max-width: 512px;
}

@media screen and (max-width: 512px) {
    .page.page-mobile {
        zoom: 125%;
    }
}

.page.page-fw {
    width: 100%;
    max-width: initial;
}

.tooltip {
    position: absolute;
    bottom: calc(100% + var(--u-4));
    left: 50%;
    background: var(--col-grey-800);
    transform: translateX(-50%);
    min-width: 50px;
    width: max-content;
    max-width: 240px;
    z-index: 200;
    text-align: center;
    color: white;
    font-weight: normal;
    font-size: 0.8em;
    padding: var(--u-8);
    text-transform: initial;
    border-radius: 2px;
    word-wrap: break-word !important;
    white-space: normal;
    line-height: 1.2em;
}

.tooltip::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    bottom: -5px;
    left: calc(50% - 2.5px);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--col-grey-800);
}

.hover-bubble {
    position: relative;
}

.hover-bubble .tooltip {
    display: none;
}

.hover-bubble:hover .tooltip {
    display: block;
}

.auth {
    background: var(--col-grey-100);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--u-24);
    padding: var(--u-16) 0;
}

.auth > * {
    z-index: 10;
}

.login-frame {
    background: #fff;
    width: 100%;
    border-radius: 2px;
    max-width: var(--u-384);
    padding: var(--u-24);
    box-shadow: var(--u-1) var(--u-1) var(--u-4) rgba(0, 0, 0, 0.07), var(--u-2) var(--u-2) var(--u-24) rgba(0, 0, 0, 0.07);
}

.login-frame .scl-button.scl-button.scl-button {
    width: 100%;
    text-align: center;
}

.login-frame .button-area {
    padding-top: 0;
    padding-bottom: var(--u-8);
}

.login-frame-attachment {
    border-radius: 2px;
    margin: -24px;
    margin-top: 24px;
    padding: 16px 24px;
    background: var(--col-grey-100);
    box-shadow: var(--u-1) var(--u-1) var(--u-4) rgba(0, 0, 0, 0.07), var(--u-2) var(--u-2) var(--u-24) rgba(0, 0, 0, 0.07);
}

.auth .atrocit-logo {
    max-width: calc(var(--u-384) * 0.6);
    height: auto;
}

.topbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: var(--col-white);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15), 0 0 16px rgba(0, 0, 0, 0.25);
    z-index: 1;
    gap: var(--u-16);
    padding-left: 40px;
    padding-right: 0;
}

.day-selector, .week-selector {
    display: flex;
    flex-direction: row;
    gap: var(--u-4);
    align-items: center;
}

/* Card related */
.card {
    background: #fff;
    box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.15), 0 4px 24px -16px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    position: relative;
}

.card-padding {
    padding: 16px 32px;
}

.card-break-padding {
    margin: 0 -32px;
}

.card-content {
    padding: var(--u-16) var(--u-24);
}

.card-loading-overlay, .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    z-index: 10;
}

.full-width-card-btn {
    display: block;
    height: var(--u-48);
    line-height: var(--u-48);
    text-align: center;
    border-top: 1px solid var(--col-grey-100);
    text-transform: uppercase;
    font-size: var(--fs-10);
    font-weight: bold;
    color: var(--col-primary-500)
}

.full-width-card-btn[disabled] {
    color: var(--col-grey-500);
}

/* DEPRECATED CARD TOOLBAR */
.card-toolbar {
    height: 40px;
    line-height: 40px;
    padding-left: 16px;
    margin-bottom: 16px;
}

.card-toolbar h2,
.title-a {
    display: inline-block;
    padding: 0;
    margin: 0;
    color: var(--col-grey-700);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1em;
}

.card-toolbar-right {
    float: right;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.card-toolbar-right .action-bar {
    padding: var(--u-2) var(--u-2);
    height: 100%;
}

.col-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--u-32);
}

.bold {
    font-weight: bold;
}

.hamburger-btn {
    position: fixed;
    top: 0;
    left: 0;
    width: 40px;
    height: 48px;
    margin: 0 8px;
    padding: 8px 0;
    z-index: 100;
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    outline: none;
}

.hamburger-btn:active {
    background: none;
    opacity: 0.8;
    outline: none;
}

.hamburger-btn:focus {
    outline: none;
}

.hamburger-btn-stripe {
    transition: transform 200ms ease-out, opacity 100ms ease-out;
}

.hamburger-btn-open .hamburger-btn-stripe:nth-child(1) {
    transform-origin: 12px top;
    transform: rotate(45deg) scaleX(0.80) translateX(12px);
}

.hamburger-btn-open .hamburger-btn-stripe:nth-child(2) {
    opacity: 0;
    transform: scaleX(0.5);
}

.hamburger-btn-open .hamburger-btn-stripe:nth-child(3) {
    transform-origin: 12px bottom;
    transform: rotate(-45deg) scaleX(0.80) translateX(12px);
}

.hamburger-btn-stripe {
    width: 100%;
    height: 4px;
    background: #fff;
}



.topbar {
    background: var(--col-primary-500);
    color: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 0 16px rgba(0, 0, 0, 0.5);
    margin-bottom: -48px;
    height: 48px;
    display: flex;
    justify-content: center;
}

.topbar-inner {
    max-width: 1280px;
    width: 100%;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topbar-spacer {
    height: 48px !important;
    min-height: 48px;
    max-height: 48px;
}

.topbar-title {
    line-height: 48px;
    font-size: var(--fs-16);
}

@media screen and (max-width: 600px) {
    .topbar-title {
        font-size: var(--fs-13);
    }
}

@media screen and (min-width: 1200px) {
    .sidebar.sidebar {
        transform: translateX(0);
        box-shadow: 0 0 var(--u-4) rgba(0, 0, 0, 0.3), 0 0 var(--u-16) rgba(0, 0, 0, 0.1);
        margin-right: 0;
        padding-top: 0;
    }

    .topbar.topbar {
        padding-left: 0;
    }

    .hamburger-btn.hamburger-btn {
        display: none;
    }
}

pre {
    margin: 0;
    font-family: inherit;
}

.scl-modal-card.scl-modal-card {
    overflow: visible;
}

.savings-stats {
    padding-bottom: var(--u-16);
}

.savings-stats > div {
    display: flex;
    justify-content: center;
}

.fancy-number {
    display: inline-block;
    padding: 8px;
    padding-top: 0;
    padding-bottom: 12px;
    border-radius: 4px;
    background: var(--col-primary-200);
    color: var(--col-primary-600);
    font-size: 2em;
    line-height: 0.8em;
    text-align: center;
}

.fancy-number.fancy-number-neg {
    background: var(--col-red-200);
    color: var(--col-red-700);
}

.fancy-number-description {
    font-size: .5em;
    text-transform: uppercase;
}

.scl-date-field-picker {
    z-index: 10;
}

.strikethrough > *:not(.no-strikethrough) {
    text-decoration: line-through;
    opacity: 0.5;
}

.green {
    color: var(--col-green-500);
}

.grey {
    color: var(--col-grey-600);
}

.red {
    color: var(--col-red-500);
}

small {
    font-size: var(--fs-9);
    opacity: 0.7;
}

.containerstay-status {
    min-width: 54px;
    text-align: center;
    font-size: var(--fs-13);
    padding: var(--u-8) var(--u-16);
    border-radius: var(--u-4);
    background: var(--col-grey-100);
    color: var(--col-grey-700);
    display: inline-block;
    font-weight: bold;
    & .fa { color: var(--col-grey-500); margin-right: var(--u-4); }

    &.containerstay-status-draft {
        background: var(--col-blue-100);
        color: var(--col-blue-700);
        & .fa { color: var(--col-blue-500); }
    }

    &.containerstay-status-requested {
        background: var(--col-orange-100);
        color: var(--col-orange-700);
        & .fa { color: var(--col-orange-500); }
    }

    &.containerstay-status-approved {
        background: var(--col-green-100);
        color: var(--col-green-700);
        & .fa { color: var(--col-green-500); }
    }

    &.containerstay-status-denied {
        background: var(--col-red-100);
        color: var(--col-red-700);
        & .fa { color: var(--col-red-500); }
    }
}