.breadcrumbs {
    display: flex;
    flex-direction: row;
    padding-bottom: var(--u-16);
}

.breadcrumbs a {
    color: var(--col-grey-600);
    text-decoration: none;
}

.breadcrumbs a:hover {
    color: var(--col-grey-700);
    text-decoration: underline;
}

.breadcrumb-separator {
    padding: 0 var(--u-4);
}

.ReactModal__Overlay--after-open {
    animation: modalFadeInBackground 300ms ease;
    animation-fill-mode: backwards;
}

/*.ReactModal__Overlay--before-close {*/
/*    animation: modalFadeInBackground 150ms ease reverse;*/
/*    animation-fill-mode: backwards;*/
/*}*/

@keyframes modalFadeInBackground {
    from { background: rgba(0, 0, 0, 0); }
    to { background: rgba(0, 0, 0, 0.5); }
}

@keyframes modalPop {
    from {
        opacity: 0;
        transform: translateY(24px) scale(0.9);
    }

    to {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}

.modal-card {
    position: relative;
    animation: modalPop 300ms ease;
    animation-fill-mode: backwards;
    min-width: 300px;
    background: #fff;
    border-radius: var(--u-4);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.10), 1px 2px 24px rgba(0, 0, 0, 0.2);
}

.modal-header.modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    padding-left: var(--u-16);
}

.modal-content {
    padding: var(--u-16);
    overflow-y: auto;
    max-height: calc(95vh - 32px);
}

.card {
    background: var(--col-white);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05), 1px 2px 16px rgba(0, 0, 0, 0.1);
}

.topbar {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05), 1px 2px 16px rgba(0, 0, 0, 0.08);
    background: var(--col-white);
    height: var(--u-48);
    padding: 0 var(--u-24);
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
}

.toolbar-wrapper {
    padding-left: var(--u-32);
    padding-right: var(--u-32);
    display: flex;
    justify-content: center;
}

@keyframes toolbarIn {
    from {
        transform: translateY(32px) scale(0.8);
        opacity: 0;
    }

    to {
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
}

@keyframes toolbarOut {
    from {
        transform: translateY(0px) scale(1);
        opacity: 1;
    }

    to {
        transform: translateY(32px) scale(0.9);
        opacity: 0;
    }
}

.toolbar {
    position: fixed;
    bottom: var(--u-16);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15), 1px 2px 16px rgba(0, 0, 0, 0.25);
    background: var(--col-grey-700);
    border-radius: 4px;
    height: var(--u-48);
    padding: 0 var(--u-24);
    color: var(--col-grey-200);
    display: flex;
    align-items: center;
    opacity: 1;
    animation: 250ms ease-out both toolbarIn;
}

.toolbar.toolbar-hidden {
    pointer-events: none;
    animation: 150ms ease-in both toolbarOut;
}

.separator-dot {
    border-radius: 50%;
    width: 3px;
    height: 3px;
    background: var(--col-primary-400);
    margin: 0 var(--u-8);
}

.layered {
    background: var(--col-grey-200);
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.sub {
    font-size: var(--fs-9);
    line-height: 1em;
    color: var(--col-grey-600);
}

.container-transport-row {
    display: flex;
    align-items: center;
    background: #fff;
    margin-bottom: var(--u-4);
    border-radius: 4px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
    padding: var(--u-4) var(--u-16);
    line-height: 1.1em;
    border: 1px solid #fff;
    transition: all 150ms ease-out;
    max-width: var(--u-768);
}

.container-transport-row-selected {
    background: var(--col-primary-100);
    border: 1px solid var(--col-primary-200);
    color: var(--col-primary-800);
}

.container-transport-row-selected .sub {
    color: var(--col-primary-600);
}


.cmd-search-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 100;
}

.cmd-search {
    border-radius: 4px;
    background: var(--col-grey-800);
    max-height: var(--u-768);
    min-width: var(--u-512);
    margin-top: var(--u-128);
    box-shadow: 1px 4px 16px rgba(0, 0, 0, 0.3), 2px 16px 48px rgba(0, 0, 0, 0.5);
    color: var(--col-grey-200);

    animation: 250ms ease-out both toolbarIn;
}

.cmd-search-summary {
    padding: var(--u-16) var(--u-16);
    padding-bottom: 0;
}

.cmd-search input {
    font-size: var(--fs-18);
    background: none;
    border: none;
    color: var(--col-grey-100);
    width: 100%;
    padding: var(--u-16);
    border-bottom: 1px solid var(--col-grey-600);
}

.cmd-search input:focus {
    outline: none;
}

.cmd-search input::placeholder {
    color: var(--col-grey-300);
}

.cmd-search-results {
    max-height: calc(4.5 * var(--u-48));
    overflow: auto;
}

.cmd-search-results::-webkit-scrollbar {
    width: var(--u-8);
}

.cmd-search-results::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.3);
}

.cmd-search-results::-webkit-scrollbar-thumb {
    background: var(--col-primary-500);
    border-radius: var(--u-4);
}

.cmd-search-result {
    display: flex;
    align-items: center;
    height: var(--u-48);
    padding-right: var(--u-16);
}

.cmd-search-result.cmd-search-result-selected {
    background: var(--col-grey-700);
}

.cmd-search-result .cmd-search-result-icon {
    width: var(--u-48);
    text-align: center;
}

.tag {
    background: var(--col-grey-700);
    font-size: var(--fs-9);
    padding: var(--u-4) var(--u-8);
    border-radius: 4px;
}

.full-empty-indicator {
    display: inline-block;
    width: 15px; /* Needs to be uneven for it to center nicely */
    height: 15px;
    border-radius: 50%;
    border: 2px solid var(--col-primary-500);
    background: var(--col-primary-200);
}

.full-empty-indicator.full-empty-indicator-full {
    background: var(--col-primary-500);
}

.drawer-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    padding-left: 16px;
}

.drawer {
    min-width: 32px;
    height: 100vh;
    background: var(--col-white);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15), 1px 2px 16px rgba(0, 0, 0, 0.1);
    z-index: 10;
    transform: translateX(calc(100% + 16px));
    transition: transform 300ms ease-out;
}

.drawer-open {
    transform: translateX(0);
}

.checkbox {
    padding: 0;
    height: 17px;
    width: 17px;
    display: block;
}

.checkbox input[type=checkbox] {
    display: none;
    border: 1px solid var(--col-grey-200);
    padding: 0;
    margin: 0;
    width: 1px;
    height: 1px;
}

.checkbox .checkbox-ui-elem {
    display: inline-block;
    position: relative;
    width: 17px;
    height: 17px;
    border: 1px solid var(--col-grey-300);
    background: var(--col-grey-100);
    border-radius: 2px;
    box-sizing: border-box;
}

.checkbox input[type=checkbox]:checked ~ .checkbox-ui-elem,
.checkbox input[type=checkbox]:indeterminate ~ .checkbox-ui-elem {
    background: var(--col-primary-400);
    width: 17px;
    height: 17px;
    border: none;
}

.checkbox input[type=checkbox]:checked ~ .checkbox-ui-elem:after {
    content: "\f00c";
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'FontAwesome';
    width: 100%;
    height: 100%;
    color: white;
    position: absolute;
    font-size: 10pt;
}

.checkbox input[type=checkbox]:indeterminate ~ .checkbox-ui-elem:after {
    content: "\f068";
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'FontAwesome';
    width: 100%;
    height: 100%;
    color: white;
    position: absolute;
    font-size: 10pt;
}

.number-value-part-decimal,
.number-value-part-fraction {
    font-size: 0.75em;
}

.number-value-int .number-value-part-fraction,
.number-value-int .number-value-part-decimal {
    font-size: 0.75em;
    opacity: 0.3;
}

.number-value .number-value-part-unit {
    font-size: 0.75em;
    opacity: 0.7;
}

@keyframes jump {
    0% {
        transform: translateY(0px) scale(1);
    }

    50% {
        transform: translateY(-6px) scale(1.1);
    }

    100% {
        transform: translateY(0px) scale(1);
    }
}

.copy-to-clipboard.copy-to-clipboard-animating {
    display: inline-block;
    animation: 200ms ease-in 0ms 1 jump;
}

.time-only-day-diff {
    font-size: 0.8em;
    color: var(--col-primary-500);
    font-weight: bold;
    display: inline-block;
    padding-left: var(--u-2);
}

.call-view-load .scl-label,
.call-view-unload .scl-label {
    padding: 0 var(--u-16);
}

.call-view-card {
    position: relative;
}

.call-view-card::before {
    content: '';
    border-color: #ffffff transparent transparent;
    border-style: solid;
    border-width: 10px;
    position: absolute;
    bottom: -19px;
    left: 50%;
    margin-left: -9px;
    z-index: 2;
    transform: scaleX(1.5);
}

.connection-end {
    border-top: 5px solid var(--col-green-800);
    width: 3rem;
}

.connection-timeline {
    border-left: 5px solid;
    height: 3rem;
}

.connection-timeline-dotted {
    border-left: 5px dashed;
    height: 3rem;
}

.connection-snapshot {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border-style: solid;
    border-width: 5px;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Shift edit screen */
.crew-col {
    background: var(--col-grey-100);
    border-radius: var(--u-4);
    min-height: 16px;
    padding: 8px;
}

.crew-col-collapsed {
    background: var(--col-grey-100);
    border-radius: var(--u-4);
    min-height: var(--u-64);
    padding: 8px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
}

.crew-col-inner {
    min-height: 24px;
}

.spot {
    margin-bottom: 8px;
    background: var(--col-grey-50);
    border-radius: var(--u-4);
    padding: var(--u-4) var(--u-8);
}

.spot:last-of-type {
    margin-bottom: 0;
}


.shift-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--u-32);
}

@media screen and (max-width: 1200px) {
    .shift-view {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 600px) {
    .shift-view {
        grid-template-columns: 1fr;
    }
}

.abs-week-row {
    display: grid;
    grid-template-columns: var(--u-256) repeat(7, minmax(0, 1fr));
}

.abs-item {
    user-select: none;
    min-width: 100%;
    height: 24px;
    cursor: pointer;
}

.abs-item.abs-item-start {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.abs-item.abs-item-end {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.abs-week-day-last .abs-item:not(.abs-item-end) {
    margin-right: -16px;
    padding-right: 16px;
    mask: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) calc(100% - 16px), rgba(255, 255, 255, 0) 100%);
}

.abs-week-day-first .abs-item:not(.abs-item-start) {
    margin-left: -16px;
    padding-left: 16px;
    mask: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 16px, rgba(255, 255, 255, 1) 100%);
}

.abs-item {
    overflow: hidden;
    padding: 0 4px;
    line-height: 24px;
    color: var(--col-primary-100);
    white-space: nowrap;
    display: flex;
    gap: var(--u-2);
    justify-content: space-between;
    font-size: var(--fs-8);
    background: var(--col-blue-600);
    position: relative;
}

.abs-item.abs-unsure {
    background: var(--col-grey-500);
    color: var(--col-grey-50);
}

.abs-item.abs-denied {
    background: var(--col-red-500);
    color: var(--col-red-100);
}

.abs-item.not-cepa-weekend:after {
    content: '';
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    height: 4px;
    background: var(--col-grey-900);
}

.abs-week-row {
    /*height: var(--u-32);*/
    padding: var(--u-2) 0;
    align-items: center;
}

.abs-week-row:not(:first-child) {
    border-top: 1px solid var(--col-grey-100);
}

.abs-week-row:last-of-type {
    border-bottom: 1px solid var(--col-grey-100);
}

.abs-week-row:not(:first-child):hover {
    background: var(--col-white);
}

.abs-week-day .absence-remark,
.abs-week-day .absence-status {
    display: none;
}

.abs-week-day .abs-item-start .absence-remark,
.abs-week-day .abs-item-start .absence-status {
    display: initial;
}

.abs-week-day-first .abs-item .absence-remark,
.abs-week-day-first .abs-item .absence-status {
    display: initial;
}

.abs-week-header {
    font-size: var(--fs-9);
    text-transform: uppercase;
    color: var(--col-grey-600);
    text-align: center;
    padding-bottom: 4px;
    margin-bottom: 4px;
    border-bottom: 1px solid var(--col-grey-100);
    line-height: 1.2em;
    align-items: flex-start;
}

.abs-week-row.abs-week-footer {
    font-size: var(--fs-9);
    text-transform: uppercase;
    color: var(--col-grey-600);
    text-align: center;
    padding-top: 4px;
    margin-top: 4px;
    border-top: 2px solid var(--col-grey-200);
    line-height: 1.2em;
    align-items: flex-start;
}

.abs-week-row.abs-week-footer-lower {
    font-size: var(--fs-9);
    text-transform: uppercase;
    color: var(--col-grey-600);
    text-align: center;
    padding-top: 4px;
    margin-top: 4px;
    border-top: 1px solid var(--col-grey-100);
    line-height: 1.2em;
    align-items: flex-start;
}

.abs-special-day {
    margin-top: var(--u-4);
    background: var(--col-orange-500);
    color: white;
    border-radius: 4px;
    padding: var(--u-4);
}

.abs-month-row {
    display: flex;
}

.abs-month-row > * {
    flex: 1;
}

.abs-month-row > *:nth-of-type(1) {
    min-width: var(--u-256);
    width: var(--u-256);
}

.abs-month-row {
    height: var(--u-32);
    align-items: center;
}

.abs-month-row:last-of-type {
    border-bottom: 1px solid var(--col-grey-200);
}

.abs-month-row:not(:first-child) {
    border-top: 1px solid var(--col-grey-200);
}

.abs-month-row:not(:first-child):hover {
    background: var(--col-white);
}

.abs-month-day .absence-remark,
.abs-month-day .absence-status {
    display: none;
}

.abs-month-day .abs-item-start .absence-remark,
.abs-month-day .abs-item-start .absence-status {
    display: initial;
}

.abs-month-day-first .abs-item .absence-remark,
.abs-month-day-first .abs-item .absence-status {
    display: initial;
}

.abs-month-header {
    font-size: var(--fs-9);
    text-transform: uppercase;
    color: var(--col-grey-600);
    text-align: center;
    padding-bottom: 4px;
    margin-bottom: 4px;
    border-bottom: 1px solid var(--col-grey-200);
    line-height: 1.2em;
    height: calc(var(--u-32) + var(--u-8));
}

.abs-month-row.abs-month-footer {
    font-size: var(--fs-9);
    text-transform: uppercase;
    color: var(--col-grey-600);
    text-align: center;
    padding-top: 4px;
    margin-top: 4px;
    border-top: 2px solid var(--col-grey-200);
    line-height: 1.2em;
    height: calc(var(--u-32) + var(--u-8));
}

.abs-month-row.abs-month-footer-lower {
    font-size: var(--fs-9);
    text-transform: uppercase;
    color: var(--col-grey-600);
    text-align: center;
    border-top: 1px solid var(--col-grey-200);
}

.abs-month-header > * {
    height: calc(var(--u-32) + var(--u-8));
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.abs-month-day .abs-item {
    display: flex;
    justify-content: center;
}

.abs-month-day {
    display: flex;
    align-items: center;
    height: var(--u-32);
}

.abs-month-day-header {
    padding: var(--u-8) 0;
}

.abs-month-day-footer {
    padding: var(--u-4) 0;
}

.abs-month-day-footer-lower {
    padding: var(--u-6) 0;
}

.abs-month-day.abs-month-day-weekend, .abs-month-day-header-weekend {
    background: rgba(0, 0, 0, 0.05);
    position: relative;
}

.abs-month-day.abs-month-day-special, .abs-month-day-header-special-day {
    background: var(--col-orange-200);
    color: var(--col-orange-800);
}

.off-screen-panel-right {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 50;
    transform: translateX(100%);
    transition: transform 300ms ease-in;
}

.off-screen-panel-right.off-screen-panel-expanded {
    transition: transform 300ms ease-out;
    transform: translateX(calc(0% - 16px));
}

.topbar.off-screen-panel-right {
    padding-top: var(--u-48);
}

.off-screen-panel-content {
    background: #fff;
    border-radius: 4px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15), 1px 2px 24px rgba(0, 0, 0, 0.2);
}

.off-screen-panel-right .off-screen-panel-btn-area {
    position: absolute;
    right: 100%;
}

.topbar ~ .off-screen-panel-right {
    padding-top: var(--u-64);
}


.dropdown-button {
    display: inline-block;
    position: relative;
}

.dropdown-button .dropdown-button-content {
    display: none;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    min-width: 100%;
    background: #fff;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1), 1px 2px 8px rgba(0, 0, 0, 0.15);
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
}

.dropdown-button .dropdown-button-content > * {
    width: 100%;
    border-top: 1px solid var(--col-grey-100);
    min-height: var(--u-32);
    display: flex;
    align-items: center;
    padding: var(--u-8) var(--u-12);
    transition: all 100ms ease-out;
    white-space: nowrap;
}

.dropdown-button .dropdown-button-content > *:first-child {
    border-top: 0;
}

.dropdown-button .dropdown-button-content > a {
    color: var(--col-grey-600);
    font-weight: bold;
    text-decoration: none;
}

.dropdown-button .dropdown-button-content > *:hover {
    background: var(--col-grey-50);
    color: var(--col-grey-700);
}

.dropdown-button .dropdown-button-content > .danger {
    background: var(--col-red-50);
    color: var(--col-red-800);
}

.dropdown-button .dropdown-button-content > .danger:hover {
    background: var(--col-red-100);
    color: var(--col-red-700);
}

.dropdown-button.dropdown-button-open {
    z-index: 10;
}

.dropdown-button .dropdown-button-open-btn > * {
    transition: transform 150ms ease-out;
}

.dropdown-button.dropdown-button-open .dropdown-button-open-btn > * {
    transform: rotateX(180deg);
}

.dropdown-button.dropdown-button-open .dropdown-button-content {
    display: flex;
}

.badge {
    background: var(--col-grey-600);
    color: var(--col-grey-50);
    font-size: var(--fs-10);
    border-radius: calc(1.7em / 2);
    padding-right: 1px;
    margin-left: 2px;
    min-width: 1.7em;
    height: 1.7em;
    line-height: 1.7em;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    box-shadow: 1px 2px 4px inset rgba(0, 0, 0, 0.2);
}

.badge.badge-primary {
    background: var(--col-primary-600);
    color: var(--col-primary-100);
}

.date-time-display {
    color: var(--col-grey-700);
    font-weight: bold;
}

.bar-divider {
    height: 100%;
    padding-left: var(--u-12);
    padding-right: var(--u-12);
    display: flex;
    justify-content: center;
}

.divider-line {
    border-right: 1px solid var(--col-grey-200);
}