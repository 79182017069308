.mobile-table {
	min-width: 100%;
	border-collapse: separate;
	border-spacing: 0;
}

.mobile-table thead {
	position: sticky;
	top: 0;
}

.mobile-table thead th {
	text-transform: uppercase;
	color: var(--col-primary-50);
	font-size: 10pt;
	letter-spacing: -0.2px;
	background: var(--col-primary-500);
	border-top: 1px solid var(--col-primary-600);
	border-left: 1px solid var(--col-primary-600);
}

.mobile-table thead th:last-of-type {
	border-right: 1px solid var(--col-primary-600);
}

.mobile-table thead tr:last-of-type th {
	border-bottom: 1px solid var(--col-primary-600);
}

.mobile-table .mobile-table-secondary-header th {
	color: var(--col-primary-100);
	font-weight: normal;
	text-transform: initial;
}

.mobile-table td {
	border-left: 1px solid var(--col-grey-100);
	border-top: 1px solid var(--col-grey-100);
}

.mobile-table td:last-of-type {
	border-right: 1px solid var(--col-grey-100);
}

.mobile-table tr:last-of-type td {
	border-bottom: 1px solid var(--col-grey-100);
}

.mobile-table td,
.mobile-table th {
	text-align: left;
	padding: 4px 4px;
	/*border: 1px solid var(--col-grey-200);*/
}

.mobile-table tr:nth-of-type(odd) td {
	background: var(--col-primary-50);
}

.mobile-table tr:nth-of-type(even) td {
	background: var(--col-white);
}

.mobile-table td {
	/*overflow-y: auto;*/
}

.mobile-table tr:hover + tr td {
	border-top-color: var(--col-primary-200);
}

.mobile-table tr:hover td {
	border-color: var(--col-primary-200);
	background: var(--col-primary-100);
}

.mobile-table td .button {
	height: 24px;
	line-height: 24px;
}

.mobile-table .tbl-center {
	text-align: center;
}

.mobile-table .tbl-fit {
	width: 0.1%;
	white-space: nowrap;
}

.mobile-table .tbl-align-right {
	text-align: end;
	font-variant-numeric: tabular-nums;
}

.mobile-table tbody {
	font-size: 12pt;
}

.mobile-table tbody td {
	height: var(--u-48);
}

.td-edimobile-table {
	position: relative;
	overflow: hidden;
}

.td-edimobile-table-indicator {
	position: absolute;
	bottom: 0;
	right: -7px;
	width: 0;
	height: 0;
	border: 7px solid transparent;
	border-bottom-color: var(--col-primary-200);
}

.mobile-table tfoot {
	position: sticky;
	bottom: 0;
}

.pagination > * {
	min-width: var(--u-32);
	text-align: center;
}

td .pagination .button {
	height: initial;
}

.pagination-wrapper {
	display: flex;
	justify-content: flex-end;
	padding-top: var(--u-16);
	padding-bottom: var(--u-16);
}

.pagination-spacer {
	display: flex;
	justify-content: flex-end;
	padding: var(--u-16) var(--u-32);
}

.subtext {
	font-size: var(--fs-10);
	color: var(--col-grey-700);
}

.mobile-table .fit {
	width: 0.1%;
	white-space: nowrap;
}

.mobile-table .stick-right {
	position: sticky;
	right: 0;
	box-shadow: -8px 0 10px rgba(0, 0, 0, 0.1);
}

.scl-date-field-picker {
	z-index: 10;
}

.horizontal-scroll-container {
	overflow-x: auto;
	overflow-y: visible;
	height: 100%;
	position: relative;
}