.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: var(--col-grey-100);
    max-height: 100%;
    height: 100%;
}

.dashboard-content {
    flex: 1;
    overflow: auto;
    display: flex;
    align-items: stretch;
}

.day-view {
    display: flex;
    align-items: flex-start;
    gap: var(--u-12);
    overflow: auto;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    /* We can enable scroll snapping, but this might get annoying real fast... */
    /*scroll-snap-type: x proximity;*/
    position: relative;
}

.day-view-content {
    display: flex;
    align-items: flex-start;
    gap: var(--u-12);
    height: auto;
    padding: 0 var(--u-12);
    overflow: visible;
}

.ship-view {
    scroll-snap-align: start;
    display: flex;
    align-self: stretch;
    flex-direction: column;
    gap: var(--u-8);
    min-width: 1250px;
}

.ship-view-title {
    position: sticky;
    top: 0;
    z-index: inherit;
}

.table-single-line {
    table-layout: fixed;
    white-space: nowrap;
}

.table-single-line th,
.table-single-line td {
    overflow: hidden;
}

.topbar button {
    height: 36px;
}

.crew-grid-wrapper {
    width: 100%; /* Make sure it fills its parent or viewport */
}

.crew-grid {
    display: flex;
    flex-direction: row;
    gap: var(--u-16);
}

.crew-new-column {
    width: 100%;
    min-width: 200px;
}

@media screen and (max-width: 750px) {
    .crew-grid {
        flex-direction: column;
        width: 100%;
        .crew-new-column {
            width: 100%;
        }
    }
}

.empty-state {
    display: grid;
    grid-template-columns: 1fr 1.3fr;
    gap: var(--u-16);
    align-items: center;
    min-height: calc(90vh - 200px);
    position: relative;
}

.empty-state-graphic {
    position: relative;
    z-index: 5;
}

.empty-state-graphic img {
    width: 100%;
}

.empty-state-text {
    position: relative;
    text-shadow: 0 0 24px rgba(255, 255, 255, 1);
    z-index: 10;
}

.hero-text {
    font-size: var(--fs-16);
    color: var(--col-grey-600);
    line-height: 1.4em;
}

@media screen and (max-width: 600px) {
    .empty-state {
        grid-template-columns: 1fr;
        grid-template-rows: min-content 1fr;
        align-items: flex-start;
    }

    .empty-state-graphic {
        display: flex;
        justify-content: center;
        margin: 0 -24px;
        overflow: hidden;
    }

    .empty-state-graphic img {
        width: 150%;
        max-width: 150%;
        max-height: 50vh;
        object-fit: contain;
        height: auto;
    }
}

.shift-wkday {
    display: grid;
    grid-template-rows: 150px repeat(auto-fit, 100px);
}

.shift-wkday-header {
    font-size: var(--fs-9);
    text-transform: uppercase;
    color: var(--col-grey-600);
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-bottom: var(--u-8);
}

.shift-wkday-early,
.shift-wkday-late,
.shift-wkday-night,
.shift-wkday-item {
    width: 100%;
    border-top: var(--col-grey-100) 1px solid;
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 1.1em;
    height: 100px;
}

.shift-wkday-item:last-of-type {
    border-bottom: var(--col-grey-100) 1px solid;
}

.shift-wkday-night {
    border-bottom: var(--col-grey-100) 1px solid;
}

.shift {
    border-radius: 4px;
    background: #fff;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07), 1px 2px 16px rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 8px;
    font-size: var(--fs-9);
    text-align: center;
}